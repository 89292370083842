<template>
  <div class="notification-card">
    <div class="notification-close">
      <Icon name="closeOutline" class="n-close-icon centered" @click="$emit('close')" />
    </div>
    <div class="main-text">{{ title }}</div>
    <div>
      <span class="secondary-text" @click="$emit('textclick')" v-html="description"></span>
      <span class="notification-card-button" @click="$emit('confirm')">{{ button }}</span>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['description', 'title', 'button']
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.notification-card {
  @extend .white-box;
  margin: 20px 20px 5px 20px;
  padding: 15px;
}

.notification-close {
  position: absolute;
  background: white;
  box-shadow: $box-shadow;
  top: -8px;
  right: -8px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  .n-close-icon {
    --stroke: #{$col-font};
    padding: 2px;
    --stroke-width: 24px;
  }
}

.notification-card-button {
  @extend .btn-secondary-small;
  float: right;
  margin-top: 10px;
}
</style>
