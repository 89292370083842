<!--

      This view displays the last two orders. It is reachable by clicking on the
      third tab (Kochen & Essen).

 -->

<template>
  <Page title="Kochen & Essen" hideback="true" allowoffline="true" scroll="true">

      <div class="info-tiles" style="margin-bottom: 20px;" v-if="$store.getters.oldCustomerJourney">
        <div class="info-tile-3" @click="$store.dispatch('goTo', 'discover')">
          <Icon name="searchOutline" class="info-tile-icon" />
          Rezepte für dich
        </div>
        <div class="info-tile-3" @click="$store.dispatch('openModal', {name: 'addrecipe'})">
          <Icon name="addOutline" class="info-tile-icon" />
          Rezepte eintragen
        </div>
        <div class="info-tile-3" @click="openFavorites()">
          <Icon name="heartOutline" class="info-tile-icon" />
          Favoriten ansehen
        </div>
      </div>

      <div class="undelivered-orders" v-show="notDeliveredOrders && notDeliveredOrders.length > 0">
        <div v-for="(order, index) in notDeliveredOrders" :key="order.id">
          <NotificationCard
            v-if="showPendingOrders[index]"
            title = "Menü aktivieren"
            :description = "'Ist deine <span style=&quot;color:#548C00;&quot; />Bestellung vom ' + dateString(order) + '</span> schon angekommen?'"
            button = "Ja"
            @confirm="() => clickConfirmArrivedOrder(index)"
            @close="() => clickHidePendingOrder(index)"
            @textclick="openOrderDetails(order)"
          />
        </div>
        <div 
          v-if="showTextAboutNotifications && showPendingOrders.filter(i => !i).length"
          :key="showTextAboutNotifications"
          class="btn-more-orders"
          @click="clickShowPendingOrders"
        >
          {{ showPendingOrders.filter(i => !i).length }} weitere Bestellung{{ showPendingOrders.filter(i => !i).length > 1 ? 'en' : '' }} einblenden
        </div>
      </div>

      <div class="up-next-title-area">
        <div class="section-title">Als Nächstes</div>
        <div class="eaten-toggle-area" @click="clickShowEatenRecipes()">
          <div class="btn-tertiary-small" v-show="!showEatenRecipes">
            Gegessene anzeigen
          </div>
          <div class="btn-tertiary-small" v-show="showEatenRecipes">
            Gegessene ausblenden
          </div>
        </div>
      </div>

      <ion-skeleton-text animated class="skeleton-card" v-if="wait"></ion-skeleton-text>
      <ion-skeleton-text animated class="skeleton-card" v-if="wait"></ion-skeleton-text>

      <div v-for="order in feedOfNextMeals" :key="order.id">
        <div v-if="!allRecipesWereEaten(order) || showEatenRecipes" class="an-order">
          <div class="order-top-bar" @click="openOrderDetails(order)">
            <div class="order-date-string">Bestellung vom {{ dateString(order) }}</div>
          </div>
          <div 
            v-for="day in order.plan.single_days.sort(this.sortByPosition)"
            :key="day.id"
            v-show="(showEatenRecipes && day.planrecipes[0].eaten) || !day.planrecipes[0].eaten"
          >
            <NotificationCard
              v-for="notification in day.planrecipes[0].recipe.notifications"
              v-show="!notification.hide"
              :key="notification.message"
              :title = "notification.title"
              :description = "notification.message"
              button = "Erledigt"
              @confirm="() => {notification.hide = true}"
              @close="() => {notification.hide = true}"
            />
            <RecipeCard
              :order="order"
              :rplan="day.planrecipes[0]"
              :dayId="day.id"
              :showAvailability="false"
              type="cooking"
              @eatMealLater="eatMealLater"
              @toggledEaten="data => toggledEaten(day, data)"
            />
          </div>
        </div>
      </div>

      <NewCard v-if="messageWaitingForDelivery" icon="bagHandleOutline">
        Dein Menü ist noch nicht da!<br>Wenn doch, aktiviere es oben.
      </NewCard>

      <NewCard v-if="messageShopNow" goTo="recipes" icon="bagHandleOutline">
        Erledige jetzt einen Einkauf,<br>um hier deine Rezepte zu sehen!
      </NewCard>

  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import RecipeCard from '@/components/cards/RecipeCard.vue'
import NewCard from '@/components/cards/NewCard.vue'
import NotificationCard from '@/components/cards/NotificationCard.vue'
import { IonSkeletonText } from '@ionic/vue'

export default {
  name: 'cookingTab',
  components: {
    NotificationCard,
    Page,
    RecipeCard,
    NewCard,
    IonSkeletonText
  },
  data () {
    return {
      wait: false,
      showPendingOrders: [],
      showEatenRecipes: false
    }
  },
  computed: {
    someOrdersAreOnTheirWay () {
      return this.$store.state.notDeliveredOrders 
          && this.$store.state.notDeliveredOrders.length > 0
    },

    allDeliveredOrdersWereEaten () {
      return this.$store.state.deliveredOrders 
          && this.$store.state.deliveredOrders.filter(o => !o.eaten) 
          && this.$store.state.deliveredOrders.filter(o => !o.eaten).length === 0
    },

    someOrdersWereDelivered () {
      return this.$store.state.deliveredOrders 
          && this.$store.state.deliveredOrders.length > 0
    },

    notDeliveredOrders () {
      if (this.$store.state.notDeliveredOrders) {
        return this.$store.state.notDeliveredOrders
      }
    },

    feedOfNextMeals () {
      if (this.$store.state.deliveredOrders) {
        const orders = this.$store.state.deliveredOrders.filter(o => !o.eaten).sort((a, b) => new Date(a.ordered_at) - new Date(b.ordered_at))

        // Record recipe impressions
        // const recipeIds = orders.map(order => order.plan.single_days).flat().map(day => day.planrecipes[0].recipe.id)
        // const impressions = recipeIds.map(recipeId => (
        //   { recipe: recipeId, flag: this.$store.state.recipeImpressionFlags.SEEN }
        // ))
        // this.$store.dispatch('recordRecipeImpression', impressions)
        return orders
      }
      return null
    },

    showTextAboutNotifications () {
      if (this.$store.state.notDeliveredOrders) {
        return !(this.showPendingOrders.length == this.$store.state.notDeliveredOrders.length // If all notifications are shown, hide the text
          && this.showPendingOrders.every(i => i === true)) // If any notification was clicked away, show the text
      }
      return false // Since there are no pending orders, hide the text
    },

    messageWaitingForDelivery () {
      // The user has nothing to eat, but is waiting for an order to arrive.
      const nothingToEat = this.feedOfNextMeals && !this.feedOfNextMeals.length || !this.feedOfNextMeals
      const waitingForFirstOrder = this.someOrdersAreOnTheirWay && !this.someOrdersWereDelivered
      const waitingForFurtherOrders = this.someOrdersAreOnTheirWay && this.allDeliveredOrdersWereEaten
      const waiting = waitingForFirstOrder || waitingForFurtherOrders
      return nothingToEat && waiting
    },

    messageShopNow () {
      // The user has nothing to eat, but is not waiting for an order to arrive
      const nothingToEat = this.feedOfNextMeals && !this.feedOfNextMeals.length || !this.feedOfNextMeals
      const waitingForFirstOrder = this.someOrdersAreOnTheirWay && !this.someOrdersWereDelivered
      const waitingForFurtherOrders = this.someOrdersAreOnTheirWay && this.allDeliveredOrdersWereEaten
      const waiting = waitingForFirstOrder || waitingForFurtherOrders
      return nothingToEat && !waiting
    }
  },
  methods: {
    openFavorites () {
      // If the user was not yet on the discover page and opened the favorites from there,
      // this route does not yet exist. Therefore, we must create it.
      // I tested that 'addRoute' does not create a route again, if it already exists.
      // Therefore, it is not necessary to check whether it already exists.
      this.$router.addRoute({
        path: '/tabs/cooking/discover/favorites',
        name: 'favorites',
        component: () => import('@/views/shopping/RecipeList'),
        props: true
      })
      this.$router.push({ name: 'favorites', params: { title: 'Deine Favoriten', endpoint: 'favorites' } })
    },
    clickShowEatenRecipes () {
      this.showEatenRecipes = !this.showEatenRecipes
    },
    allRecipesWereEaten (order) {
      if (order) {
        return order.plan.single_days.map(day => day.planrecipes[0].eaten).every(i => i)
      }
    },
    toggledEaten(day, data) {
      day.planrecipes[0].eaten = data.eaten
    },
    eatMealLater (data) {
      // Calculate the newest position. We must look at all existing
      // positions, select the biggest number and then add 1.
      let biggestPosition = 0
      data.order.plan.single_days.forEach(day => {
        if (day.position > biggestPosition) {
          biggestPosition = day.position
        }
      })
      const newPosition = biggestPosition + 1

      // 1. Save the new position locally
      const deliveredOrders = this.$store.state.deliveredOrders
      const orderIndex = deliveredOrders.findIndex(o => o.id === data.order.id)
      const theOrder = deliveredOrders[orderIndex]
      const dayIndex = theOrder.plan.single_days.findIndex(d => d.id === data.dayId)
      const theDay = theOrder.plan.single_days[dayIndex]

      theDay.position = newPosition
      theOrder.plan.single_days[dayIndex] = theDay
      deliveredOrders[orderIndex] = theOrder

      this.$store.dispatch('save', { key: 'deliveredOrders', value: deliveredOrders })

      // 2. Send update to the Api
      const details = {
        url: this.$store.state.api.plan.daily,
        id: data.dayId,
        data: { position: newPosition },
        method: 'PATCH'
      }
      this.$store.dispatch('apiSend', details)
    },
    openOrderDetails (order) {
      if (order && order.id) {
        this.$store.dispatch('save', { key: 'order', value: order }).then(() => {
          this.$store.dispatch('goTo', 'order')
        })
      }
    },
    buildNotifications () {
      if (this.$store.state.notDeliveredOrders) {
        this.showPendingOrders = this.$store.state.notDeliveredOrders.map(() => false)
        this.showPendingOrders[0] = true
      }
    },
    dateString (order) {
      // If you want to current plan order date, use index = 0
      // For the previous plan order date, use index = 1
      if (order.ordered_at) {
        let d = new Date(order.ordered_at)
        let today = new Date()
        let date = ''
        if (today.getFullYear() > d.getFullYear()) {
          date = d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear()
        } else {
          date = d.getDate() + '.' + (d.getMonth() + 1) + '.'
        }
        return date
      }
      return null
    },
    sortByPosition (a, b) {
      return a.position - b.position
    },
    clickShowPendingOrders () {
      this.showPendingOrders = this.$store.state.notDeliveredOrders.map(() => true)
    },
    clickHidePendingOrder (index) {
      this.showPendingOrders[index] = false
    },
    clickConfirmArrivedOrder (index) {
      const theArrivedOrder = this.$store.state.notDeliveredOrders[index]
      
      // Download the plan for this order.
      this.$store.dispatch('getPlanById', theArrivedOrder.plan_id).then(plan => {
          if (plan.single_days && plan.single_days.length) {
            theArrivedOrder['plan'] = plan
            
            // Set the order to delivered locally by removing it from
            // the state variable notDeliveredOrders and pushing it
            // into the state variable deliveredOrders
            const notDeliveredOrders = this.$store.state.notDeliveredOrders.filter(o => o.id !== theArrivedOrder.id)
            this.$store.dispatch('save', { key: 'notDeliveredOrders', value: notDeliveredOrders })

            let deliveredOrders = this.$store.state.deliveredOrders
            if (!deliveredOrders) {
              deliveredOrders = []
            }

            // Ensure the order was not added to the deliveredOrders array before.
            // This can happen if the user runs this function twice while the Api
            // responds slowly.
            if (!deliveredOrders.find(o => o.id === theArrivedOrder.id)) {
              deliveredOrders.push(theArrivedOrder)
              this.$store.dispatch('save', { key: 'deliveredOrders', value: deliveredOrders })

              // Tell the Api that this order was delivered
              const details = {
                url: this.$store.state.api.shop.order,
                id: theArrivedOrder.id,
                data: {
                  delivered: true
                },
                method: 'PATCH'
              }

              this.$store.dispatch('apiSend', details)
            }

            this.$store.dispatch('showNotification', { message: 'Dein Menü wurde erfolgreich ganz unten hinzugefügt. Markiere Rezepte als gegessen, die du schon gegessen hast, oder welche du nicht mehr essen möchtest.', type: 'Success'})
          }
        })
    }
  },
  mounted () {
    this.$store.dispatch('save', { key: 'tab', value: 'cooking' })
    this.$store.dispatch('logAnalyticsEvent', { name: 'opened_currentplan' })
  },
  updated () {
    this.buildNotifications()
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.undelivered-orders {
  margin-bottom: 30px;
}

.up-next-title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 0 0;
  .section-title {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.up-next-title {
  text-align: left;
  font-size: 1.7rem;
}

.an-order {
  margin-bottom: 50px;
}

.eaten-toggle-area {
  display: flex;
  justify-content: end;
  align-items: center;
  color: $col-gray-font;
  div {
    display: flex;
  }
}

.order-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  position: sticky;
  top:0;
  z-index: 210;
  background: $app-bg;
}

.order-date-string {
  @extend .btn-tertiary;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.btn-more-orders {
  @extend .btn-tertiary-small;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 32px;
}

</style>
